import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { DOCUMENT_RESOURCE_TYPE, GENERIC_EXCEPTION_CODE, MODE, STATUS, VALUE_ADDED_SERVICE_ROUTES } from '../../../../../utils/constant';
import { HTTP_STATUS } from '../../../../../utils/types';
import { MachinePayload, useVASMasterCategoryService } from '../../../../../services/useVASMasterCategoryService';
import MachineFormTemplate from '../../../../templates/DashBoard/ValueAddedService/Machine/MachineForm.template';
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import { useFileService } from '../../../../../services/useFileService';
import { makeRoute } from '../../../../../utils/helper';
import { MACHINE_FORM_STATE } from './MachineCreate.page';
import MachineReviewTemplate from '../../../../templates/DashBoard/ValueAddedService/Machine/MachineReviewTemplate';

const validationSchema = Yup.object().shape({
    serviceCategory: Yup.object().shape({
        id: Yup.number().required('Service Category is required'),
        label: Yup.string().required('Service Category is required')
    }).required('Service Category is required'),
    usc: Yup.object().shape({
        id: Yup.number().required('USC is required'),
        label: Yup.string().required('USC is required')
    }).required('USC is required'),
    uscId: Yup.number().required('USC is required'),
    machineName: Yup.string().max(30, "Machine Name cannot be more than 30 characters").required("Machine Name is required"),
    machineVendor: Yup.string().max(30, "Brand/Manufacturer cannot be more than 30 characters").required("Brand/Manufacturer is required"),
    modelNumber: Yup.string().max(30, "Model Number cannot be more than 30 characters").required("Model Number is required"),
    machineType: Yup.string().max(30, "Machine Type cannot be more than 30 characters").required("Machine Type is required"),
    manufactureYear: Yup.number().min(1000, 'Enter a valid Year Of Manufacture').max(9999, 'Enter a valid Year Of Manufacture').required('Year Of Manufacture is required'),
    operationalSpeed: Yup.number().moreThan(0, "Operational Speed should be more than zero").required("Operational Speed is required"),
    equipmentNames: Yup.array().of(Yup.string()).min(1, 'At least one Equipment Name Requirement is required').required('Equipment Name is required'),
    equipmentDescription: Yup.string().max(500, "Equipment Description cannot be more than 500 characters").required("Equipment Description is required"),
    materialHandle: Yup.string().max(30, "Material Type cannot be more than 30 characters").required("Material Type is required"),
    qualityStandard: Yup.string().max(30, "Quality Standard cannot be more than 30 characters").required("Quality Standard is required"),
    materialGrade: Yup.string().max(30, "Material Grade cannot be more than 30 characters").required("Material Grade is required"),
    serviceability: Yup.string().max(30, "Serviceability cannot be more than 30 characters").required("Serviceability is required"),
    minOrderQuantity: Yup.number().moreThan(0, "Minimum Order Quantity should be more than zero").required("Minimum Order Quantity is required"),
    maxOrderQuantity: Yup.number().moreThan(0, "Maximum Order Quantity should be more than zero").required("Maximum Order Quantity Speed is required"),
    serviceCharge: Yup.object().shape({
        normalServiceCharge: Yup.number().moreThan(0, "Normal Service Charges/MT should be more than zero").required("Normal Service Charges/MT is required"),
        minimumServiceCharge: Yup.number().moreThan(0, "Minimum Service Charges/MT should be more than zero").required("Minimum Service Charges/MT is required"),
        maximumServiceCharge: Yup.number().moreThan(0, "Maximum Service Charges/MT should be more than zero").required("Maximum Service Charges/MT is required"),
    }),
    machineDescription: Yup.string().max(500, "Machine Description cannot be more than 500 characters").required("Machine Description is required"),
    warehouse: Yup.object().shape({
        id: Yup.number(),
        label: Yup.string().required('Warehouse is required')
    }).required('Warehouse is required'),
});

const MachineUpdatePage: React.FC = () => {
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const fileService = useFileService();
    const [machine, setMachine] = useState<any | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const params = useParams();

    const [formState, setFormState] = useState<MACHINE_FORM_STATE>(1);

    const loadMachine = async () => {
        vasMasterCategoryService.getMachineById(Number(params.id))
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setMachine(res.data.data)
                }
            }).catch((error) => {
                setMachine(null);
                console.error("Error Fetching Machine: ", error);
                showSnackbar('error', "Error while fetching Machine data");
            })
    }

    useEffect(() => {
        loadMachine()
    }, [Number(params.id)])

    const onBack = () => {
        navigate(makeRoute(VALUE_ADDED_SERVICE_ROUTES.MACHINES_VIEW, { params: { id: Number(params.id) } }));
    };

    const goToMachineListing = () => {
        navigate(VALUE_ADDED_SERVICE_ROUTES.MACHINES_LIST("MACHINE"));
    }

    const uploadImage = async (id: number) => {
        try {
            const result = [];
            const fileToUpload = formik.values.images.filter(image => image instanceof File);
            for (let image of fileToUpload) {
                if (image instanceof File) {
                    const presignedUrlResponse = await fileService.generatePresignedUrl(image.name, DOCUMENT_RESOURCE_TYPE.VAS_MACHINE, id);
                    if (presignedUrlResponse && presignedUrlResponse.data && presignedUrlResponse.data.data && presignedUrlResponse.data.data.presignedUrl) {
                        const presignedUrl = presignedUrlResponse.data.data.presignedUrl;
                        const imageUploadResponse = await fileService.uploadImageUsingPreSignedUrls(image, presignedUrl);
                        if (imageUploadResponse.status === HTTP_STATUS.OK) {
                            let res = await fileService.createFile({
                                resourceType: "VAS_MACHINES",
                                resourceId: id,
                                path: `vas_machines/${id}/${image.name.split(" ").join("_")}`,
                                mimeType: image.type,
                                // metaData: {
                                //     documentName: image.name,
                                //     documentType: null,
                                //     documentCategory: "IMAGE"
                                //   },
                            })
                            if (res?.status === HTTP_STATUS.OK) {
                                result.push(true)
                            }
                            else {
                                result.push(false)
                                showSnackbar("error", `Failed to upload image: ${image.name}`)
                            }
                        } else {
                            result.push(false)
                            showSnackbar("error", `Failed to upload image: ${image.name}`)
                        }
                    } else {
                        result.push(false)
                        showSnackbar("error", 'Failed to generate presigned URL')
                    }
                } else {
                    result.push(false)
                    showSnackbar("success", "Machine Service created successfully");
                }
            }
            if (!result.includes(false)) {
                setIsLoading(false);
                showSnackbar("success", "Machine Service uploaded successfully");
            } else {
                showSnackbar("error", 'File to upload Images:')
            }
        } catch (error) {
            setIsLoading(false);
            showSnackbar("error", 'File upload failed:')
        }
    }


    useEffect(() => {
        formik.setValues({
            serviceCategory: {
                label: machine?.uscResponseDTO?.services?.name ?? "",
                id: machine?.uscResponseDTO?.services?.id ?? ""
            },
            usc: {
                label: machine?.uscResponseDTO?.uscCode ?? "",
                id: machine?.uscResponseDTO?.id ?? ""
            },
            uscId: machine?.uscResponseDTO?.id ?? null,
            subAttributeIds: subAttributeIds ?? {},
            machineName: machine?.machineName ?? "",
            machineVendor: machine?.machineVendor ?? "",
            modelNumber: machine?.modelNumber ?? "",
            location: machine?.location ?? "",
            machineType: machine?.machineType ?? "",
            manufactureYear: machine?.manufactureYear ?? null,
            operationalSpeed: machine?.operationalSpeed ?? "",
            equipmentName: "",
            equipmentNames: machine?.equipmentNamesJson ?? [],
            equipmentDescription: machine?.equipmentDescription ?? "",
            materialHandle: machine?.materialHandle ?? "",
            qualityStandard: machine?.qualityStandard ?? "",
            materialGrade: machine?.materialGrade ?? "",
            serviceability: machine?.serviceability ?? "",
            minOrderQuantity: machine?.minOrderQuantity ?? null,
            maxOrderQuantity: machine?.maxOrderQuantity ?? null,
            serviceCharge: {
              normalServiceCharge: machine?.serviceChargeJson?.normalServiceCharge ?? null,
              minimumServiceCharge: machine?.serviceChargeJson?.minimumServiceCharge ?? null,
              maximumServiceCharge: machine?.serviceChargeJson?.maximumServiceCharge ?? null,
            },
            machineDescription: machine?.machineName ?? "",
            status: machine?.status ?? STATUS.ACTIVE,
            attributes: machine?.subAttributeResponseDTO ?? [],
            images: machine?.vasFileDetails ?? [],
            imageListToDelete: [],
            warehouse: {
                label: machine?.warehouse ??  "",
                id: machine?.warehouseId ?? ""
            },
        });
    }, [machine]);

    const updateMachine = async (machineRequestBody: MachinePayload) => {
        const requestBody: any = {
            uscId: machineRequestBody.uscId,
            subAttributeIds: machineRequestBody.subAttributeIds,
            machineName: machineRequestBody.machineName,
            machineVendor: machineRequestBody.machineVendor,
            modelNumber: machineRequestBody.modelNumber,
            location: null,
            machineType: machineRequestBody.machineType,
            manufactureYear: machineRequestBody.manufactureYear,
            operationalSpeed: machineRequestBody.operationalSpeed,
            equipmentName: machineRequestBody.equipmentName,
            equipmentNames: machineRequestBody.equipmentNames,
            equipmentDescription: machineRequestBody.equipmentDescription,
            materialHandle: machineRequestBody.materialHandle,
            qualityStandard: machineRequestBody.qualityStandard,
            materialGrade: machineRequestBody.materialGrade,
            serviceability: machineRequestBody.serviceability,
            minOrderQuantity: machineRequestBody.minOrderQuantity,
            maxOrderQuantity: machineRequestBody.maxOrderQuantity,
            serviceCharge: {
              normalServiceCharge: machineRequestBody.serviceCharge.normalServiceCharge,
              minimumServiceCharge: machineRequestBody.serviceCharge.minimumServiceCharge,
              maximumServiceCharge: machineRequestBody.serviceCharge.maximumServiceCharge,
            },
            machineDescription: machineRequestBody.machineDescription,
            status: machineRequestBody.status ?? STATUS.ACTIVE,
            warehouseId: machine.warehouse.id
         }
        vasMasterCategoryService.createMachine(requestBody, { id: Number(params.id)})
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    uploadImage(Number(params.id))
                    showSnackbar('success', "Machine Updated");
                    goToMachineListing();
                } else if (res.data.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY) {
                    showSnackbar('error', "Machine already exists. Please try again");
                }
            }).catch((error) => {
                showSnackbar('error', "Failed to update Machine");
            })
    }

    const subAttributeIds = machine?.subAttributeResponseDTO.reduce((acc: any, attribute: any) => {
        attribute.subAttributes.forEach((subAttr: any) => {
            acc[subAttr.id] = {
                minTolerance: subAttr.minTolerance,
                maxTolerance: subAttr.maxTolerance
            };
        });
        return acc;
    }, {});

    const formik = useFormik<MachinePayload>({
        initialValues: {
            serviceCategory: {
                label: machine?.uscResponseDTO?.services?.name ?? "",
                id: machine?.uscResponseDTO?.services?.id ?? ""
            },
            usc: {
                label: machine?.uscResponseDTO?.uscCode ?? "",
                id: machine?.uscResponseDTO?.id ?? ""
            },
            uscId: machine?.uscResponseDTO?.id ?? null,
            subAttributeIds: subAttributeIds ?? {},
            machineName: machine?.machineName ?? "",
            machineVendor: machine?.machineVendor ?? "",
            modelNumber: machine?.modelNumber ?? "",
            location: machine?.location ?? "",
            machineType: machine?.machineType ?? "",
            manufactureYear: machine?.manufactureYear ?? null,
            operationalSpeed: machine?.operationalSpeed ?? "",
            equipmentName: "",
            equipmentNames: machine?.equipmentNamesJson ?? [],
            equipmentDescription: machine?.equipmentDescription ?? "",
            materialHandle: machine?.materialHandle ?? "",
            qualityStandard: machine?.qualityStandard ?? "",
            materialGrade: machine?.materialGrade ?? "",
            serviceability: machine?.serviceability ?? "",
            minOrderQuantity: machine?.minOrderQuantity ?? null,
            maxOrderQuantity: machine?.maxOrderQuantity ?? null,
            serviceCharge: {
              normalServiceCharge: machine?.serviceChargeJson?.normalServiceCharge ?? null,
              minimumServiceCharge: machine?.serviceChargeJson?.minimumServiceCharge ?? null,
              maximumServiceCharge: machine?.serviceChargeJson?.maximumServiceCharge ?? null,
            },
            machineDescription: machine?.machineName ?? "",
            status: machine?.status ?? STATUS.ACTIVE,
            attributes: machine?.subAttributeResponseDTO ?? [],
            images: machine?.vasFileDetails ?? [],
            imageListToDelete: [],
            warehouse: {
                label: machine?.warehouse ??  "",
                id: machine?.warehouseId ?? ""
            },
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            updateMachine({ ...values, status: values.status })
        }
    })

    const setFormStateTo = (formState: MACHINE_FORM_STATE) => () => {
        setFormState(formState)
    }
    const activeFormStep = useMemo(() => {
        switch (formState) {
            case MACHINE_FORM_STATE.MACHINE_REVIEW: return <MachineReviewTemplate mode={MODE.UPDATE} onBack={onBack} formik={formik} setFormStateTo={setFormStateTo}/>;
            default: return <MachineFormTemplate mode={MODE.UPDATE} onBack={onBack} formik={formik} setFormStateTo={setFormStateTo}/>;
        }
    }, [formState, formik])

    return (
        <div>
            {SnackBarComponent}
            {activeFormStep}
        </div>
    )
}

export default MachineUpdatePage
