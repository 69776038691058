import { useEffect, useState } from "react";
import TabsV2 from "../../molecules/TabsV2/TabsV2";
import ProductViewTemplate from "../DashBoard/Inventory Management/ProductView.template";
import { createUseStyles } from "react-jss";
import { ITabsSchema } from "../../molecules/Tabs/Tabs";
import DeliveryInformationTemplate from "./DeliveryInformation.template";
import { ADD_EXISTING_PRODUCT } from "../../../utils/types";
import Button from "../../atoms/Button/Button";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { AddVendorProductSectionProps } from "./AddExistingProduct.template";
import { capitalizeFirstLetter } from "../../../utils/helper";

const useStyles = createUseStyles((theme: any) => ({
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
}));


const ExistingProductDetailsTemplate: React.FC<AddVendorProductSectionProps> = ({ warehouses, formik, setCurrentSectionTo, handleCancel, setProductDetailsTo, productDetails }) => {

    const classes = useStyles();
    const { showSnackbar } = useSnackbar();

    const [activeTab, setActiveTabTo] = useState("1");

    const schema: ITabsSchema[] = warehouses?.map((warehouse: any, index: number) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <DeliveryInformationTemplate warehouseId={warehouse.id} formik={formik} index={index} />,
    }));

    const handleBack = () => {
        setCurrentSectionTo(ADD_EXISTING_PRODUCT.SELECT_PRODUCT);
    }
    
    const handleNext = () => {
        if (formik.errors && formik.errors.warehouseWiseProductDetails && formik.errors.warehouseWiseProductDetails.length > 0) {
            showSnackbar("error", "Minimum weight is required and must be greater than and equal to 0");
            return;
        }
        setCurrentSectionTo(ADD_EXISTING_PRODUCT.PRODUCT_REVIEW);
    }
    const handleTabChange = (tab: string | number) => {
        setActiveTabTo(tab.toString());
    };

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);

    return (
        <div>
            <div className=' grid gap-4 '>
                <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm`}>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.barHeading} my-auto`}>Select Product Type </div>
                        </div>
                        <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.barHeading} my-auto`}>Select Product</div>
                        </div>
                        <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activeProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                            <div className={`${classes.barHeading} my-auto font-semibold`}>Add Product Details</div>
                        </div>
                        <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.barHeading} my-auto`}>Review</div>
                        </div>
                        <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                    </div>
                </div>
                <ProductViewTemplate product={productDetails} showButton={false} formik={formik} />
            </div>
            <div className='mt-6'>
                {schema.length > 0 && (
                    <TabsV2 schema={schema} value={activeTab} setValue={handleTabChange} />
                )}
            </div>
            <div className='flex justify-between'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                    />
                    {/* <Button
                        variant="secondaryContained"
                        label="Save As Draft"
                        onClick={handleBack}
                    /> */}
                    <Button
                        variant="primaryContained"
                        label="Review"
                        disabled={!warehouses?.some((warehouse) => warehouse.id)}
                        onClick={handleNext}
                    />
                </div>
            </div>
        </div>
    )
}

export default ExistingProductDetailsTemplate;
