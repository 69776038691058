import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Tabs, { ITabsSchema } from '../../../molecules/Tabs/Tabs';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '../../../atoms/Button/Button';
import AddNewInvetoryModalTemplate from './AddNewInvetoryModal.template';
import EmptyInventoryStateTemplate from './EmptyInventoryState.template';

import TableV2, { ColumnType } from '../../../organisms/Table/TableV2';
import ViewEyeIcon from '../../../../assets/icons/outlineView.svg';
import deleteIcon from '../../../../assets/icons/deleteIcon.svg';
import { FormikProps, useFormik } from 'formik';
import { IAddNewInventoryDetail, IAddVendorInventoryForm } from './AddNewVendorInventory.template';
import { useVendorInventoryService } from '../../../../services/useVendorInventoryService';
import { HTTP_STATUS, STATUS } from '../../../../utils/types';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { capitalizeFirstLetter, convertConstantsToCamelCase } from '../../../../utils/helper';
import * as Yup from 'yup';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import ConfirmPrimaryChangeModal from '../../OnBoarding/ConfirmPrimaryChangeModal.templeate';
import SuccessFailurePopup from '../../../molecules/SuccessFailurePopup/SuccessFailurePopup';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    textHeading2: {
        color: theme.palette.textV2.primary.primary900
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    textHeading3: {
        color: theme.palette.backgroundV2.primary.primary900
    },
    lineColor: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    UsnText: {
        color: theme.palette.backgroundV2.primary.primary500
    }
}));

interface Column {
    label: string;
    key: string;
    type: ColumnType;
    props: { className: string };
}

export enum INVENTORY_WAREHOUSE {
    DELHI = 1,
    MUMBAI = 2,
}
export interface IIVendorInventory {
    id: number,
    usnNumber: string;
    VendorUsnNumber: string;
    GrossWeight: number;
    netWeight: number;
    packingType: string;
    ageingDays: number;
    entryDate: number;
    MillTestCertificateLink: string;
}
interface IAddVendorInventoryFormTemplate {
    formik: FormikProps<IAddVendorInventoryForm>;
    buttonShow?: boolean;
    setWareHouseId?: Dispatch<SetStateAction<number>>;
    editOn?:boolean; 
    isMillTestSelected?: Boolean;
    setIsMillTestSelectedTo?: (isMillTest: boolean) => void;
    setSelectedFileTo?:  (file: File | null) => void;
    selectedFile?: File | null;
}

interface IInventoryAddViewProps {
    warehouseId: number;
}

export interface iModalFormikForm {
    wareHouses: IAddNewInventoryDetail[];
}

const AddInventorysectionTemplate: React.FC<IAddVendorInventoryFormTemplate> = ({ formik, buttonShow, setWareHouseId, editOn, isMillTestSelected, setIsMillTestSelectedTo, setSelectedFileTo, selectedFile}) => {
    const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useAuthenticatedUser();
    const [activeTab, setActiveTab] = useState(INVENTORY_WAREHOUSE.DELHI);
    const [gstinModalDialogOpen, setGstinModalDialogOpen] = useState<boolean>(false);
    const vendorInventoryService = useVendorInventoryService();
    const [warehouseData, setWarehouseData] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [availableStock, setAvailabelStockTo] = useState<number | null>(null);
    const [totalUsnNo, setTotalUsnNo] = useState<number | null>(null);

    const initialValues: iModalFormikForm = {
        wareHouses: [{
            warehouseId: 0,
            vendorUsnCode: '',
            grossWeight: 0,
            netWeight: 0,
            packagingType: '',
            isMillTest: false,
        }]
    };

    const validationSchema = Yup.object().shape({
        wareHouses: Yup.array().of(
            Yup.object().shape({
                vendorUsnCode: Yup.string(),
                grossWeight: Yup.number().min(1, 'Gross Weight must be greater than 0').required('Gross Weight is required'),
                netWeight: Yup.number().min(1, 'Net Weight must be greater than 0').required('Net Weight is required'),
                packagingType: Yup.string().required('Packaging Type is required'),
            })
        )
    });

    const modalFormik = useFormik<iModalFormikForm>({
        initialValues,
        validationSchema,
        onSubmit: async () => {
            const updatedWarehouseDetails = [...formik.values.warehouseDetails, ...modalFormik.values.wareHouses];
            formik.setFieldValue('warehouseDetails', updatedWarehouseDetails)
            modalFormik.setFieldValue('wareHouses', []);
            modalFormik.setTouched({});
            setGstinModalDialogOpen(false);
        },
    });



    const fetchWarehouse = async () => {
        if (user?.businessId) {
            try {
                const wareHouseResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
                if (wareHouseResponse.status === HTTP_STATUS.OK) {
                    const warehouses = wareHouseResponse?.data?.data?.content;
                    setWarehouseData(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }

    };
    useEffect(() => {
        fetchWarehouse();
    }, [user?.businessId]);

    const InventoryAddView: React.FC<IInventoryAddViewProps> = ({ warehouseId }) => {
        const classes = useStyles();
        const navigate = useNavigate();
        const [vendorInventories, setVendorInventoriesTo] = useState<IAddNewInventoryDetail[]>(formik.values.warehouseDetails);
        const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
        const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
        const handleChange = () => {
            setGstinModalDialogOpen(true);
            const newWarehouseDetail = {
                warehouseId: warehouseId,
                vendorUsnCode: '',
                grossWeight: 0,
                netWeight: 0,
                packagingType: '',
                isMillTest: false,
            };
            modalFormik.setFieldValue('wareHouses', [newWarehouseDetail])
        }

        const handleDelete = (index: number) => {
            setIsDeleteModalOpen(true);
            setDeleteIndex(index);
        }

        const handleDialogClose = (event: boolean) => {
            if (event === true && deleteIndex != null) {
                setIsDeleteModalOpen(false);
                const newWarehouseDetails = [...formik.values.warehouseDetails]
                const initialIndex = newWarehouseDetails.findIndex(
                    (warehouseData) => warehouseData.warehouseId === warehouseId
                );
                newWarehouseDetails.splice(deleteIndex+initialIndex , 1)
                formik.setFieldValue('warehouseDetails',newWarehouseDetails);
            } else {
                setIsDeleteModalOpen(false);
            }
            if(event === true){
               setSelectedFileTo?.(null); 
            }
        };

        const Action = (id: number) => (
            <div className='flex items-center justify-center gap-x-3'>
               <button onClick={() => handleDelete(id)}><img src={deleteIcon} alt={deleteIcon} /></button>
            </div>
        )

        const getTableColumns = (edit: boolean): Column[] => {
            const columns: Column[] = [
                { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
                { label: "USN Number", key: "vendorUsnNumber", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Vendor USN Number", key: "GrossWeight", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Gross Weight(MT)", key: "thickness", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Net Weight (MT)", key: "netWeight", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Packaging Type", key: "packagingType", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Ageing Days", key: "ageingDays", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Entry Date", key: "entryDate", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Mill Test Certificate", key: "millTestCertificate", type: "custom" as ColumnType, props: { className: '' } },
                { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
            ];
            if (!edit) {
                return columns.slice(0, -1);
            }
            return columns;
        };
        const getRecords = () => {
            return vendorInventories
                .filter((vendorInventory: IAddNewInventoryDetail) => {
                    return vendorInventory.warehouseId ? vendorInventory.warehouseId === warehouseId : true;
                })
                .map((vendorInventory: IAddNewInventoryDetail, index) => ([
                    index + 1,
                    vendorInventory.usnCode ? vendorInventory.usnCode : "NA",
                    vendorInventory.vendorUsnCode ? vendorInventory.vendorUsnCode : "NA",
                    vendorInventory.grossWeight,
                    vendorInventory.netWeight,
                    convertConstantsToCamelCase(vendorInventory.packagingType),
                    vendorInventory.ageingDays ? vendorInventory.ageingDays : "NA",
                    vendorInventory.entryDate ? vendorInventory.entryDate : "NA",
                    vendorInventory.millTestCertificate ? "NA" : "NA",
                    Action(index)
                ]));
        };

        const getSchema = () => ({
            id: "1",
            pagination: {
                total: 0,
                currentPage: 0,
                isVisible: false,
                limit: 1,
                handleChangePage: () => { },
                handleChangeRowsPerPage: () => { }
            },
            columns: getTableColumns(!editOn) ?? []
        })

        const handleAddOtherInventory = () => {
            setGstinModalDialogOpen(true);
            const newWarehouseDetail: IAddNewInventoryDetail = {
                warehouseId: warehouseId,
                vendorUsnCode: '',
                grossWeight: 0,
                netWeight: 0,
                packagingType: '',
                isMillTest: false,
            };
            modalFormik.setFieldValue('wareHouses', [newWarehouseDetail]);
        }
        useEffect(() => {
            setWareHouseId && setWareHouseId(warehouseId);
        }, [modalFormik.values.wareHouses])

        useEffect(() => {
            calculateTotalSizeOfInventory()
        }, [vendorInventories])

        const calculateTotalSizeOfInventory = () => {
            let totalCalculatedSize: number = 0;

            vendorInventories
                .filter((vendorInventory: IAddNewInventoryDetail) => {
                    return vendorInventory.warehouseId ? vendorInventory.warehouseId === warehouseId : true;
                })
                .forEach((vendorInventory: IAddNewInventoryDetail, index) => (
                    totalCalculatedSize += Number(vendorInventory.netWeight) || 0

                ));
            const Totallength = vendorInventories.filter((vendorInventory: IAddNewInventoryDetail) => {
                return vendorInventory.warehouseId ? vendorInventory.warehouseId === warehouseId : true;
            }).length
            setAvailabelStockTo(totalCalculatedSize);
            setTotalUsnNo(Totallength);
            return totalCalculatedSize;
        }

        return (
            <>
                {formik?.values?.warehouseDetails?.length > 0 ?
                    <>

                        {buttonShow && <div className='absolute top-1 right-0'>
                            <Button
                                variant="secondaryContained"
                                label="+Add Inventory"
                                onClick={handleAddOtherInventory}
                            />
                        </div>}
                        <div className={` grid gap-4`}>
                            <text className={` ${classes.UsnText} text-lg font-medium `}> Total No of USN: {totalUsnNo} </text>
                            <TableV2 schema={getSchema()} records={getRecords()} />
                        </div>
                        {
                        isDeleteModalOpen &&
                        <SuccessFailurePopup
                    width="458px"
                    height="400px"
                    status={false}
                    heading="Delete Inventory?"
                    description="Are you sure you want to delete inventory for this USN? "
                    setShowPopup={setIsDeleteModalOpen}
                    button1={{
                        text: 'Yes',
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: () => handleDialogClose(true),
                    }}
                    button2={{
                        text: 'No',
                        variant: "secondaryContained",
                        size: "large",
                        onClick: () => handleDialogClose(false)
                    }}
                />
                    }
                    </>

                    :
                    <EmptyInventoryStateTemplate handleChange={handleChange} />

                }

            </>
        )
    }
    const schema: ITabsSchema[] = warehouseData?.map((warehouse: { id: number, name: string }, index: number) => ({
        label: capitalizeFirstLetter(warehouse?.name),
        component: <InventoryAddView warehouseId={warehouse.id} />,
    }));
    return (
        <div className=' grid gap-4 '>
            <text className={`${classes.textHeading3} text-2xl font-medium`}>Inventory Details </text>
            {
                formik.values.warehouseDetails.length > 0 &&
                <>
                    <hr className={`${classes.lineColor} border-t `} />
                    <text className=''>Total number of Location: {warehouseData.length} </text>
                </>

            }
            <div className={`${classes.selectBox} rounded-xl p-4 grid gap-4`}>
                <text className={`${classes.textHeading3} text-base font-medium `}>USN Details </text>
                <div className='w-full relative'>
                    <Tabs schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
                </div>


                <text className={`${classes.textHeading3}text-base font-medium`}>Total Inventory Available </text>

                <div className={`${classes.selectBox} rounded-xl grid p-6 w-1/4`}>
                    <text className={` ${classes.radioColor} text-sm font-normal`}>Total Stock Available</text>
                    <text className={` ${classes.radioColor} text-base font-medium`}>{availableStock} MT</text>
                </div>
                <AddNewInvetoryModalTemplate
                    dialogOpen={gstinModalDialogOpen}
                    setDialogOpen={setGstinModalDialogOpen}
                    formik={formik}
                    modalFormik={modalFormik}
                    isMillTestSelected = {isMillTestSelected}
                    setIsMillTestSelectedTo = {setIsMillTestSelectedTo}
                    setSelectedFileTo = {setSelectedFileTo}
                    selectedFile = {selectedFile}
                />
            </div>
        </div>
    )
}

export default AddInventorysectionTemplate