import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { ADD_VENDOR_INVENTORY_STATES, HTTP_STATUS, IPagination } from '../../../../utils/types';
import Button from '../../../atoms/Button/Button';
import ProductViewTemplate from './ProductView.template';
import AddInventorysectionTemplate from './AddInventorysection.template';
import { AddVendorInventorySectionProp } from './AddNewVendorInventory.template';
import { useVendorInventoryService } from '../../../../services/useVendorInventoryService';
import { MODE, initialPaginationValues } from '../../../../utils/constant';
import { IProductData } from './SelectProduct.template';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.backgroundV2.secondary.secondary950
    },
    activeProgressBar: {
        backgroundColor: theme.palette.backgroundV2.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.borderV2.tertiary.tertiary100
    },
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    textHeading: {
        color: theme.palette.textV2.primary.primary950
    },
    textHeading2: {
        color: theme.palette.textV2.primary.primary900
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    radioColor: {
        color: theme.palette.textV2.tertiary.tertiary700
    },
    textHeading3: {
        color: theme.palette.backgroundV2.primary.primary900
    },
    lineColor: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
}));


const AddUsnDetailTemplate: React.FC<AddVendorInventorySectionProp> = ({ formik, setCurrentSectionTo, setWareHouseId, mode,isMillTestSelected, setIsMillTestSelectedTo, setSelectedFileTo, selectedFile}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [selectedClassType, setSelectedClassTypeValue] = useState<string>('STANDARD');
    const vendorInventoryService = useVendorInventoryService();
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const { user } = useAuthenticatedUser();
    const [productsData, setProductsData] = useState<IProductData | null>(null);
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const handleCancel = () => {
        navigate('/dashboard/inventory-management');
    };
    const handleBack = () => {
        setCurrentSectionTo(ADD_VENDOR_INVENTORY_STATES.PRODUCT);
    }
    const onNext = () => {
        setCurrentSectionTo(ADD_VENDOR_INVENTORY_STATES.REVIEW);
    }

    const getVedorProductbyId = async () => {
        if (user?.businessId) {
            try {
                const productId = formik?.values?.productId ?? 0;
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId);
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setProductsData({
                        id: productData.id,
                        productId: productData.productId,
                        skuCode: productData.standardProductDetails.upcCode,
                        productName: productData.productUpcTitle,
                        standardProductDetails: productData.standardProductDetails
                    });
                }
            } catch (error) {
                showSnackbar('error', 'Vendor Products fetch failed');
            }
        }
    };
    useEffect(() => {
        getVedorProductbyId();
    }, [user?.businessId]);

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);
    
    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-3 h-3 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto font-semibold`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Select Product</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Add USN Details </div>
                    </div>
                    <div className={`${classes.activeProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4 '>
                <div className='flex  justify-between'>
                    <div className=' grid gap-1/2'>
                        <text className={`${classes.textHeading} text-base font-medium`}> Add USN Details</text>
                        <text className={`${classes.barHeading} text-xs font-normal`}> Please add USN details for the locations the product is available </text>
                    </div>
                </div>
                <ProductViewTemplate
                    product={productsData}
                    showButton={false}
                />
                <AddInventorysectionTemplate
                    formik={formik}
                    buttonShow={true}
                    setWareHouseId={setWareHouseId}
                    editOn={false}
                    isMillTestSelected = {isMillTestSelected}
                    setIsMillTestSelectedTo = {setIsMillTestSelectedTo}
                    setSelectedFileTo = {setSelectedFileTo}
                    selectedFile = {selectedFile}
                />
            </div>
            {mode === MODE.EDIT ?
                <div className='flex items-end gap-6 justify-self-end'>
                    <Button
                        variant="tertiaryContained"
                        label="Cancel"
                        onClick={handleCancel}
                        size='medium'
                    />
                    <Button
                        variant="primaryContained"
                        label="Review"
                        onClick={onNext}
                        size='medium'
                    />
                </div> :
                <div className=' flex justify-between'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                    size='medium'
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                        size='medium'
                    />
                    <Button
                        variant="primaryContained"
                        label="Review"
                        onClick={onNext}
                        size='medium'
                    />
                </div>
            </div>
                }
        </div>
    )
}

export default AddUsnDetailTemplate