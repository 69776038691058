import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { FormikProps, useFormik } from 'formik';
import { createUseStyles } from 'react-jss';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { ADD_VENDOR_PRICE_STATES, HTTP_STATUS, STATUS } from '../../../utils/types';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useVendorInventoryService } from '../../../services/useVendorInventoryService';
import SelectPriceProductTypeTemplate from '../../templates/VendorManagement/SelectVendorProductType.template';
import SelectPriceProductTemplate from '../../templates/VendorManagement/SelectPriceProduct.template';
import AddPriceDetailTemplate from '../../templates/VendorManagement/AddPriceDetail.template.';
import PriceManagementReviewTemplate from '../../templates/VendorManagement/PriceManagementReview.template';
import { useVendorPriceService } from '../../../services/useVendorPriceSerivce';
import SelectVendorProductTypeTemplate from '../../templates/VendorManagement/SelectVendorProductType.template';
import { CUSTOMER_ROUTES, REGEX } from '../../../utils/constant';
import moment from 'moment';

export interface IVendorProductPriceDetails {
    id?: number;
    warehouseId: number;
    marketPrice: number;
    reserveSalesPrice: number;
    validityTo: string;
    validityFrom: string;
    name: string;
    status: string;
}

export interface IVendorProductPriceForm {
    productType: string;
    productId: number | null;
    vendorProductPriceDetails: IVendorProductPriceDetails[];
    status?: string;
}

export interface IVendorProductPriceSectionProps {
    formik: FormikProps<IVendorProductPriceForm>;
    setWareHouseId?: Dispatch<SetStateAction<number>>;
    setCurrentSectionTo: (section: ADD_VENDOR_PRICE_STATES) => void;
    warehouses?: { id: number, name: string }[];
    mode?: string
}

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.v2.text.primaryDark
    }
}));

const VendorProductPriceCreatePage: React.FC = () => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const vendorPriceService = useVendorPriceService();
    const vendorInventoryService = useVendorInventoryService();

    const [currentSection, setCurrentSection] = useState<ADD_VENDOR_PRICE_STATES>(ADD_VENDOR_PRICE_STATES.PRODUCT_TYPE);
    const [warehouses, setWarehousesTo] = useState<{ id: number, name: string }[]>([{ id: 0, name: '' }]);
    const today = moment().startOf('day');

    const fetchWarehouses = async () => {
        if (user?.businessId) {
            try {
                const warehousesResponse = await vendorInventoryService.getAllWarehouseMappedVendor({ vendorId: user?.businessId, status: STATUS.ACTIVE });
                if (warehousesResponse.status === HTTP_STATUS.OK) {
                    const warehouses = warehousesResponse?.data?.data?.content;
                    setWarehousesTo(warehouses);
                }
            } catch (error) {
                showSnackbar('error', 'Warehouses fetch failed');
            }
        }
    };

    const initialValues: IVendorProductPriceForm = {
        productType: '',
        productId: null,
        vendorProductPriceDetails: warehouses?.map((warehouse: { id: number, name: string }) => ({
            warehouseId: warehouse?.id,
            marketPrice:  0,
            validityFrom: today.format('YYYY-MM-DD'),
            validityTo: today.format('YYYY-MM-DD'),
            reserveSalesPrice: 0,
            name: warehouse?.name,
            status: STATUS.ACTIVE
        })) || []
    };

    const validationSchema = Yup.object().shape({
        productType: Yup.string().required('Product Type is required'),
        productId: Yup.number().required('Product ID is required').positive('Product ID must be a positive number').integer('Product ID must be an integer'),
        vendorProductPriceDetails: Yup.array().of(
            Yup.object().shape({
                marketPrice: Yup.number()
                    .required('Market Price is required')
                    .min(0, 'Market Price must be greater than or equal to zero.').test('is-decimal-precision', 'Market Price can only have up to two decimal places', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString());
                    }),
                reserveSalesPrice: Yup.number()
                    .required('Reserve Sales Price is required')
                    .min(0, 'Reserve Sales Price must be greater than or equal to zero.')
                    .test('is-less-than-market', 'Reserve Sales Price must be less than Market Price', function(value) {
                        const { marketPrice } = this.parent;
                        return value <= marketPrice;
                    }).test('is-decimal-precision', 'Reserve Sales Price can only have up to two decimal places', (value) => {
                        return REGEX.TWO_DECIMAL.test(value.toString())
                    }),
                validityFrom: Yup.date().required('Price Validity From is required').min(today.toDate(), 'Validity From date must be after todays date.'),
                validityTo: Yup.date().required('Price Validity To is required').min(Yup.ref('validityFrom'), 'Validity To date must be after Validity From date.').min(today.toDate(), 'Validity To date must be after todays date.'),
            })
        )
    });

    const formik = useFormik<IVendorProductPriceForm>({
        initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            const { vendorProductPriceDetails } = formik.values;
            const filteredVendorProductPriceDetails = vendorProductPriceDetails.filter(warehouse => 
                !(warehouse.marketPrice === 0 && warehouse.reserveSalesPrice === 0)
            );
            setSubmitting(true);
            const body = filteredVendorProductPriceDetails.map(warehouse => ({
                vendorId: user?.businessId,
                vendorProductId: Number(formik?.values?.productId),
                warehouseId: warehouse?.warehouseId,
                marketPrice: warehouse?.marketPrice,
                validityFrom: warehouse?.validityFrom,
                validityTo: warehouse?.validityTo,
                reserveSalesPrice: warehouse?.reserveSalesPrice,
                status: warehouse?.status
            }));
            try {
                const vendorProductPriceResponse = await vendorPriceService.createProductPrices(body);
                if (vendorProductPriceResponse.status === HTTP_STATUS.OK) {
                    navigate(`${CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_LISTING}`);
                }
            }
            catch (error) {
                showSnackbar("error", `Vendor Product Price update failed ${error}`)
            }
        },
    });

    const vendorProductPriceSectionProps: IVendorProductPriceSectionProps = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
        warehouses: warehouses,
    };

    const VendorInventorySectionView = useMemo(() => {
        switch (currentSection) {
            case ADD_VENDOR_PRICE_STATES.PRODUCT_TYPE:
                return <SelectVendorProductTypeTemplate {...vendorProductPriceSectionProps} />;
            case ADD_VENDOR_PRICE_STATES.PRODUCT:
                return <SelectPriceProductTemplate {...vendorProductPriceSectionProps} />
            case ADD_VENDOR_PRICE_STATES.ADD_PRICE:
                return <AddPriceDetailTemplate {...vendorProductPriceSectionProps} />;
            case ADD_VENDOR_PRICE_STATES.REVIEW:
                return <PriceManagementReviewTemplate {...vendorProductPriceSectionProps} />;
            default:
                return <SelectPriceProductTypeTemplate {...vendorProductPriceSectionProps} />;
        }
    }, [currentSection, formik]);

    useEffect(() => {
        fetchWarehouses();
    }, [user?.businessId]);

    return (
        <div className='grid gap-6 justify-items-stretch '>
            <div className={`${classes.titleText} font-semibold text-lg`}>Add Price</div>
            {VendorInventorySectionView}
        </div>
    )
}

export default VendorProductPriceCreatePage