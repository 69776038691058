import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { IUSCRequestParams, IUSCSearchParams, useVASMasterCategoryService } from '../../../../../services/useVASMasterCategoryService';
import { HTTP_STATUS, IPagination } from '../../../../../utils/types';
import { VALUE_ADDED_SERVICE_ROUTES, initialPaginationValues } from '../../../../../utils/constant';
import { useSnackbar } from '../../../../../hooks/useSnackBar';
import USCListTemplate from '../../../../templates/DashBoard/ValueAddedService/USC/USCList.template';
import Button from '../../../../atoms/Button/Button';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.textV2.primary.primary950,
    },
}));

const initialUSCFiltersValues: IUSCSearchParams = {
    name: "",
    status: "ALL",
    sort: "createdAt,desc",
}

const initialUscRequestFilters: IUSCRequestParams = {
    superServiceIds: [],
    mainServiceIds: [],
    serviceCategoryIds: [],
};

const USCListPage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const vasMasterCategoryService = useVASMasterCategoryService();
    const [filters, setFilters] = useState<IUSCSearchParams>(initialUSCFiltersValues);
    const [uscRequestFilters, setUscRequestFiltersTo] = useState<any>(initialUscRequestFilters);
    const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
    const [usc, setUsc] = useState<any>([])
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const refreshUsc = async () => {
        const payload: IUSCRequestParams = {
            superServiceIds: uscRequestFilters?.superServiceIds?.length > 0 ? uscRequestFilters?.superServiceIds : null,
            mainServiceIds: uscRequestFilters?.mainServiceIds?.length > 0 ? uscRequestFilters?.mainServiceIds : null,
            serviceCategoryIds: uscRequestFilters?.serviceCategoryIds?.length > 0 ? uscRequestFilters?.serviceCategoryIds : null,
        };
        await vasMasterCategoryService.getUSCs(
            {
                page: pagination.page,
                size: pagination.size,
                sort: filters?.sort,
                name: filters?.name?.trim(),
                status: filters?.status === "ALL" ? "" : filters?.status,
            },
            payload
        )
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    const { totalElements, totalPages } = res?.data?.data;
                    setPagination({
                        ...pagination,
                        totalPages: totalPages,
                        totalRecords: totalElements
                    });
                    setUsc(res?.data?.data?.content)
                }
            }).catch(error => {
                showSnackbar('error', error);
                setUsc([]);
            })
    }

    const handleFiltersChange = (name: string, value: any) => {
        setPagination({ ...pagination, page: 0 });
        setFilters({ ...filters, [name]: value ?? "" });
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: newPage
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 0,
            size: parseInt(event.target.value, 10)
        }));
    };

    useEffect(() => {
        refreshUsc();
    }, [pagination.page, pagination.size, filters, uscRequestFilters]);

    const handleCreate = () => {
        navigate(`${VALUE_ADDED_SERVICE_ROUTES.USCS_CREATE}`);
    };

    return (
        <div className='grid gap-y-4 mt-5'>
            <div className='flex justify-between'>
                <div className={`${classes.heading} text-base font-semibold my-auto`}>USCs</div>
                <Button variant='primaryContained' label={"+ Add USC"} onClick={handleCreate}/>
            </div>
            {SnackBarComponent}

            <USCListTemplate
                usc={usc}
                pagination={pagination}
                handlePaginationChange={handlePaginationChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleFiltersChange={handleFiltersChange}
                filters={filters}
                uscRequestFilters={uscRequestFilters}
                setUscRequestFiltersTo={setUscRequestFiltersTo}
            />
        </div>
    )
}

export default USCListPage