import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import Radio from '@mui/material/Radio';
import RadioActive from '../../../../assets/images/radioActive.svg';
import RadioInActive from '../../../../assets/images/radioInactive.svg'
import productDemo from '../../../../assets/images/productDemo.png';
import { IProductData } from './SelectProduct.template';
import { IProductSpecifications } from '../../VendorManagement/ProductCatalogueView.template';
import ViewProductModalTemplate from './ViewProductModal.template';
interface IProductViewProps {
    product: IProductData | null;
    handleChange?: (event: any) => void;
    showButton?: boolean;
    showAttributes?: boolean;
    selectedValue?: string | number;
    formik?: any;
    showUsnDetails?: boolean
}
const useStyles = createUseStyles((theme: any) => ({
    barHeading: {
        color: theme.palette.textV2.tertiary.tertiary600
    },
    selectBox: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: `0px 0px 24px 0px ${theme.palette.boxShadow.primaryDark}`,
        },
    },
    lineColor: {
        border: `1px solid ${theme.palette.backgroundV2.tertiary.tertiary100}`,
    },
    attributeText: {
        color: theme.palette.textV2.primary.primary1000,
    },
    skuCode: {
        color: theme.palette.textV2.tertiary.tertiary400,
    },
    attributeValue: {
        color: theme.palette.textV2.tertiary.tertiary700,
    },
    img: {
        maxWidth: "146px",
        height: "146px"
    },
    smallimg: {
        maxWidth: "89px",
        height: "80px"

    },
    location: {
        backgroundColor: theme.palette.backgroundV2.primary.primary50,
        borderRadius: "12px"
    }
}));
const ProductViewTemplate: React.FC<IProductViewProps> = ({ product, handleChange, showButton, formik, showAttributes, showUsnDetails
}) => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    const onViewHandle = () => {
        setDialogOpen(true)
    }

    return (
        <div className={`${classes.selectBox} rounded-xl p-6 flex gap-6`}>
            <div className='flex items-center'>
                {showButton &&
                    <Radio
                        checked={formik?.values?.productId == product?.id}
                        onChange={handleChange}
                        value={product?.id}
                        icon={<img src={RadioInActive} sizes='large' />}
                        checkedIcon={<img src={RadioActive} sizes='large' />}
                        className='w-max'
                    />
                }
            </div>

            {showUsnDetails ? (
                <div className='grid gap-4 w-full'>
                    <div className='flex justify-between gap-6'>
                        <div>
                            <img src={productDemo} alt="productDemo" className={showUsnDetails ? classes.smallimg : classes.img} />
                        </div>
                        <div className='flex   gap-40 justify-between w-full'>
                            <div className='grid'>
                                <text className={`${classes.attributeValue} font-semibold text-2xl`}>  {product?.productName} </text>
                                <div className='grid gap-1.5 mt-3'>
                                    <text className={`${classes.skuCode} text-xs font-normal`}>SKU Code</text>
                                    <text className={`${classes.attributeValue} font-semibold text-base`}>{product?.skuCode}</text>
                                </div>
                            </div>
                            <Button
                                variant="primaryContained"
                                label="View Product"
                                onClick={onViewHandle}
                            />
                            <div className='hidden'>
                                {dialogOpen && <ViewProductModalTemplate product={product} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />}
                            </div>
                        </div>
                    </div>
                    <hr className={`${classes.lineColor} border-t `} />
                    <div>
                        <div className='flex gap-5 w-full justify-between'>
                            {product?.standardProductDetails?.attributes?.productSpecification?.map(
                                (spec: IProductSpecifications) => (
                                    <div className="grid gap-1.5" key={spec.name}>
                                        <span className={`${classes.attributeText} text-sm font-normal`}>
                                            {spec.name}
                                        </span>
                                        <span className={`${classes.attributeValue} font-semibold text-base `}>
                                            {spec.minValue === spec.maxValue
                                                ? `${spec.minValue} ${spec.uom?.toLowerCase()}`
                                                : `${spec.minValue} - ${spec.maxValue} ${spec.uom?.toLowerCase()}`.trim()}
                                        </span>

                                    </div>
                                )
                            )}
                            {showAttributes &&
                                <div className="flex justify-between flex-1">
                                    <div>
                                        <span className={`${classes.attributeText} text-sm font-normal`}>
                                            Thickness
                                        </span>

                                        <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                            {product?.thickness?.toLowerCase() ?? "-"}
                                        </span>
                                    </div>
                                    <div>
                                        <span className={`${classes.attributeText} text-sm font-normal`}>
                                            Width
                                        </span>

                                        <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                            {product?.width?.toLowerCase() ?? "-"}
                                        </span>
                                    </div>
                                    <div>
                                        <span className={`${classes.attributeText} text-sm font-normal`}>
                                            Length
                                        </span>
                                        <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                            {product?.length?.toLowerCase() ?? "-"}
                                        </span>
                                    </div>
                                    <div>
                                        <span className={`${classes.attributeText} text-sm font-normal`}>
                                            Temper
                                        </span>
                                        <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                            {product?.temper?.toLowerCase() ?? "-"}
                                        </span>
                                    </div>
                                </div>
                            }
                            {
                                showUsnDetails &&
                                <div className={`${classes.location} p-4 w-[299px]`}>
                                    <div className='flex gap-x-6'>
                                        <div>
                                            <div className={`${classes.attributeText} text-sm `}>Locations</div>
                                            <div className={`${classes.attributeValue} block font-semibold text-center`}>{product?.vendorProductStockDetails?.locations }</div>
                                        </div>
                                        <div>
                                            <div className={`${classes.attributeText} text-sm `}>Total USNs</div>
                                            <div className={`${classes.attributeValue} block font-semibold text-center`}>{product?.vendorProductStockDetails?.totalUsns}</div>
                                        </div>
                                        <div>
                                            <div className={`${classes.attributeText} text-sm`}>Total Quantity</div>
                                            <div className={`${classes.attributeValue} block font-semibold text-center`}>{product?.vendorProductStockDetails?.totalQuantity}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className='flex '>
                        <img src={productDemo} alt="productDemo" className={showUsnDetails ? classes.smallimg : classes.img} />
                    </div>
                    <div className=' grid gap-4  w-full'>
                        <div className='flex   gap-40 justify-between w-full'>
                            <div className='grid'>
                                <text className={`${classes.attributeValue} font-semibold text-2xl`}>  {product?.productName} </text>
                                <div className='grid gap-1.5 mt-3'>
                                    <text className={`${classes.skuCode} text-xs font-normal`}>SKU Code</text>
                                    <text className={`${classes.attributeValue} font-semibold text-base`}>{product?.skuCode}</text>
                                </div>
                            </div>
                            <Button
                                variant="primaryContained"
                                label="View Product"
                                onClick={onViewHandle}
                            />
                            <div className='hidden'>
                                {dialogOpen && <ViewProductModalTemplate product={product} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />}
                            </div>
                        </div>
                        <hr className={`${classes.lineColor} border-t `} />
                        <div className='flex gap-5 w-full justify-between'>
                            {product?.standardProductDetails?.attributes?.productSpecification?.map(
                                (spec: IProductSpecifications) => (
                                    <div className="grid gap-1.5" key={spec.name}>
                                        <span className={`${classes.attributeText} text-sm font-normal`}>
                                            {spec.name}
                                        </span>
                                        <span className={`${classes.attributeValue} font-semibold text-base `}>
                                            {spec.minValue === spec.maxValue
                                                ? `${spec.minValue} ${spec.uom?.toLowerCase()}`
                                                : `${spec.minValue} - ${spec.maxValue} ${spec.uom?.toLowerCase()}`.trim()}
                                        </span>

                                    </div>
                                )
                            )}
                            {showAttributes &&
                                <div className="flex gap-x-36">
                                    <div>
                                        <span className={`${classes.attributeText} text-sm font-normal`}>
                                            Thickness
                                        </span>

                                        <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                            {product?.thickness?.toLowerCase() ?? "-"}
                                        </span>
                                    </div>
                                    <div>
                                        <span className={`${classes.attributeText} text-sm font-normal`}>
                                            Width
                                        </span>

                                        <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                            {product?.width?.toLowerCase() ?? "-"}
                                        </span>
                                    </div>
                                    <div>
                                        <span className={`${classes.attributeText} text-sm font-normal`}>
                                            Length
                                        </span>
                                        <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                            {product?.length?.toLowerCase() ?? "-"}
                                        </span>
                                    </div>
                                    <div>
                                        <span className={`${classes.attributeText} text-sm font-normal`}>
                                            Temper
                                        </span>
                                        <span className={`${classes.attributeValue} block font-semibold text-base `}>
                                            {product?.temper?.toLowerCase() ?? "-"}
                                        </span>
                                    </div>
                                </div>
                            }
                            {
                                showUsnDetails &&
                                <div className={`${classes.location} p-4 w-[299px]`}>
                                    <div className='flex gap-x-6'>
                                        <div>
                                            <div className={`${classes.attributeText} text-sm `}>Locations</div>
                                            <div className={`${classes.attributeValue} block font-semibold text-center`}>{product?.vendorProductStockDetails?.locations}</div>
                                        </div>
                                        <div>
                                            <div className={`${classes.attributeText} text-sm `}>Total USNs</div>
                                            <div className={`${classes.attributeValue} block font-semibold text-center`}>{product?.vendorProductStockDetails?.totalUsns}</div>
                                        </div>
                                        <div>
                                            <div className={`${classes.attributeText} text-sm`}>Total Quantity</div>
                                            <div className={`${classes.attributeValue} block font-semibold text-center`}>{product?.vendorProductStockDetails?.totalQuantity}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </>
            )}

        </div>


    )
}

export default ProductViewTemplate