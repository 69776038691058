import React from "react";
import Box from "@mui/material/Box";
import {
    default as MuiTextField,
    TextFieldProps,
} from "@mui/material/TextField";
import { styled } from "@mui/system";
import { createUseStyles } from "react-jss";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const useStyles = createUseStyles((theme: any) => ({
    label: {
        color: theme.palette.textV2.tertiary.tertiary600,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px"
    },
    textField: {
        "& .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root":{
            padding:"0px"
        },
        "& .MuiInputBase-root": {
            border: "1px solid",
            borderColor: theme.palette.borderV2.tertiary.tertiary200,
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: 8,
            color: theme.palette.textV2.primary.primary900,
            "&:hover": {
                borderColor: theme.palette.borderV2.primary.primary500,
                borderWidth: 2,
                outline: "none",
            },
            "& .MuiInputBase-input": {
                // padding: "16px",
                padding:"16px 16px 16px 16px !important",
                border:"none",
                "&::placeholder": {
                    color: `${theme.palette.textV2.tertiary.tertiary900} !important`,
                    fontWeight: 400
                },
            },
            "& .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.border.secondaryDark,
            },
            "&:focus-within": {
                outline: "none",
                borderColor: theme.palette.borderV2.primary.primary500,
                borderWidth: 2,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                outline: "none",
                borderWidth: 0,
                borderColor: theme.palette.borderV2.primary.primary500,
            },
            "& .Mui-disabled": {
                backgroundColor: theme.palette.background.secondaryDarkAccent,
                fontWeight: 500,
                borderColor: theme.palette.textV2.tertiary.tertiary200,
                borderRadius: "8px",
                WebkitTextFillColor: theme.palette.textV2.primary.primary900,
                fontSize: "16px"
            },
        },
        "& .Mui-error": {
            border: "1px solid",
            borderColor: theme.palette.borderV2.tertiary.tertiary200,
            fontSize: "16px",
            fontWeight: 400,
            borderRadius: 8,
            backgroundColor: theme.palette.textV2.failure.failure50
        }
    },

    "@media (max-width: 480px)": {
        textField: {
            "& .MuiInputBase-root": {
                border: "1px solid",
                borderColor: theme.palette.borderV2.tertiary.tertiary200,
                fontSize: "14px",
                fontWeight: 500,
                borderRadius: 8,
                color: theme.palette.textV2.primary.primary900,
                "&:hover": {
                    borderColor: theme.palette.borderV2.primary.primary500,
                    borderWidth: 2,
                    outline: "none",
                },
                "& .MuiInputBase-input": {
                    // padding: "16px",
                    "&::placeholder": {
                        color: `${theme.palette.textV2.tertiary.tertiary900} !important`,
                        fontWeight: 500
                    }
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    padding:"none !important",
                    outline: "none",
                    borderWidth: 0,
                    borderColor: theme.palette.border.secondaryDark,
                },
                "&:focus-within": {
                    outline: "none",
                    borderColor: theme.palette.borderV2.primary.primary500,
                    borderWidth: 2,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    borderWidth: 0,
                    borderColor: theme.palette.borderV2.primary.primary500,
                },
                "& .Mui-disabled": {
                    backgroundColor: theme.palette.background.secondaryDarkAccent,
                    fontWeight: 400,
                    borderColor: theme.palette.textV2.tertiary.tertiary200,
                    borderRadius: "8px",
                    WebkitTextFillColor: theme.palette.textV2.primary.primary900,
                    fontSize: "14px",
                    color: theme.palette.textV2.alphaDark.alphaDark60,
                },
            },
            "& .Mui-error": {
                border: "1px solid",
                borderColor: theme.palette.borderV2.tertiary.tertiary200,
                fontSize: "14px",
                fontWeight: 400,
                borderRadius: 8,
                backgroundColor: theme.palette.textV2.failure.failure50
            }
        },
        label: {
            color: theme.palette.textV2.tertiary.tertiary600,
            fontSize: "14px",
            lineHeight: "16.1px"
        },
    },
}));

const TextFieldV2 = styled((props: TextFieldProps) => {
    const classes = useStyles();
    return (
        <div className={`flex flex-col gap-2 relative ${props.disabled ? 'pointer-events-none select-none': ''}`}>
            {props.label && (
                <div className={`${classes.label} w-max`}>
                    {props.label}
                </div>
            )}
            <MuiTextField InputProps={{...props.InputProps}} {...props} label="" className={classes.textField} helperText={null} />
            {props.error && !!props.helperText && <ErrorMessage message={props.helperText as string} size={props.size as string} />}
        </div>
    );
})();
export default TextFieldV2;